import React, { useContext } from 'react';
import AdminLayout from './AdminLayout';
const Dashboard = () => {
    return (
      <AdminLayout>
        <>
          <h1>Dashboard</h1>
        </>
      </AdminLayout>
    );
  };
  
  export default Dashboard;